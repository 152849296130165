import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { useLocation } from '@reach/router'

const PhotoGridStyles = styled.div`
  display: grid;
  gap: 2rem;
`

const PhotoStyles = styled.div`
  position: relative;
  overflow: hidden;
  &:hover {
    .info {
      .overlay {
        transform: translateY(0);
        opacity: 1;
        transition: all 0.25s ease;
      }
      .title {
        transform: translateX(0);
        opacity: 1;
      }
      .tags {
        transform: translateX(0);
        opacity: 0.6;
      }
    }
  }
  .info {
    position: absolute;
    bottom: 0;
    min-height: 200px;
    width: 100%;
    .overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      transform: translateY(200px);
      background: linear-gradient(
        0deg,
        rgba(16, 1, 0, 0.9) 0%,
        rgba(16, 1, 0, 0.7) 30%,
        rgba(16, 1, 0, 0) 100%
      );
      opacity: 0;
      transition: all 0.6s ease 0.1s;
    }
    .content {
      position: absolute;
      bottom: 0;
      display: grid;
      padding: 3rem;
      gap: 1rem;
    }
    .title {
      color: #fff;
      margin: 0;
      font-size: 3rem;
      transform: translateX(-2rem);
      transition: transform 0.4s ease, opacity 0.3s ease;
      opacity: 0;
      a {
        color: inherit;
        text-decoration: none;
        transition: color 0.2s ease;
        &::after {
          display: inline-block;
          content: '➸';
          color: var(--secondary-color);
          transition: transform 0.2s ease;
          margin-left: 1rem;
        }
        &:hover {
          color: var(--secondary-color);
          &::after {
            transform: translateX(1rem);
          }
        }
      }
    }
    .tags {
      transform: translateX(-1.5rem);
      opacity: 0;
      transition: transform 0.3s ease 0.15s, opacity 0.2s ease 0.15s;
    }
  }
`

const TagStyles = styled.div`
  display: inline;
  a {
    color: #fff;
    font-size: 1.8rem;
    font-weight: 300;
    margin-right: 0.6rem;
    &:hover {
      text-decoration: underline;
    }
    &::after {
      content: ',';
    }
  }
  &:last-child {
    a::after {
      content: '';
    }
  }
`

function SinglePhoto({ photo }) {
  const location = useLocation()

  return (
    <PhotoStyles>
      <Img fluid={photo.image.asset.fluid} alt={photo.name} />
      {location.pathname !== `/project/${photo.project.slug.current}` ? (
        <div className="info">
          <div className="overlay" />
          <div className="content">
            <h2 className="title">
              <Link to={`/project/${photo.project.slug.current}`}>
                {photo.project.name}
              </Link>
            </h2>
            <div className="tags">
              {photo.tags.map(tag => (
                <SingleTag tag={tag} />
              ))}
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </PhotoStyles>
  )
}

function SingleTag({ tag }) {
  return (
    <TagStyles>
      <Link to={`/tag/${tag.slug.current}`}>{tag.name}</Link>
    </TagStyles>
  )
}

export default function PhotoList({ photos }) {
  return (
    <PhotoGridStyles>
      {photos.map(photo => (
        <SinglePhoto key={photo.id} photo={photo} />
      ))}
    </PhotoGridStyles>
  )
}
