import React from 'react'
import { graphql } from 'gatsby'

import PhotoList from '../components/PhotoList'

export default function Home({ data: { photos }, pageContext }) {
  return <PhotoList photos={photos.nodes} />
}

export const query = graphql`
  query PhotosQuery {
    photos: allSanityPhoto(
      filter: { featured: { eq: true } }
      sort: { fields: priority, order: DESC }
    ) {
      nodes {
        id
        name
        image {
          asset {
            fluid {
              ...GatsbySanityImageFluid
            }
          }
        }
        date
        category {
          title
        }
        project {
          name
          slug {
            current
          }
        }
        tags {
          name
          slug {
            current
          }
        }
      }
    }
  }
`
